<template>
  <v-container>
    <v-row class="pa-4 mb-4" justify="space-between">
      <div>
        <h2 class="primary--text">
          Entreprises
        </h2>
      </div>
      <div>
        <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
      </div>
      <EntrepriseDialog operation="create">
        <template v-slot:activator="{on, attrs}">
          <v-btn v-bind="attrs" v-on="on" color="secondary" class="white--text">
            <v-icon>mdi-plus</v-icon>
            Ajouter une entreprise
          </v-btn>
        </template>
      </EntrepriseDialog>
    </v-row>
    <v-simple-table ref="table" class="overflow-x-auto overflow-y-auto">
      <template v-slot:default>
        <thead>
        <tr class="primary">
          <th class="white--text text-left">Nom de l'entreprise</th>
          <th class="white--text text-left">Métiers</th>
          <th class="white--text text-left">Téléphone</th>
          <th class="white--text"></th>
        </tr>
        <tr v-for="entreprise in entreprises" :key="entreprise.id">
          <td>
            <h3>
              <router-link class="text-decoration-none" :to="'/entreprises/' + entreprise.id + '/'">{{
                  entreprise.name
                }}
              </router-link>
            </h3>
          </td>

          <td>
            <v-chip color="primary" v-for="metier in entreprise.metiers" :key="metier.id">{{ metier.name }}</v-chip>
          </td>
          <td>{{ entreprise.tel }}</td>
          <td></td>
        </tr>
        </thead>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import {EntrepriseRepository} from "@/repository/entreprises";
import EntrepriseDialog from "@/views/EntrepriseDialog";

export default {
  name: "EntrepriseList",
  components: {EntrepriseDialog},
  async mounted() {
    this.entreprises = await this.repository.fetch();
  },
  data() {
    return {
      loading: false,
      repository: new EntrepriseRepository(),
      entreprises: [],
    }
  }
}
</script>

<style scoped>

</style>