import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"pa-4 mb-4",attrs:{"justify":"space-between"}},[_c('div',[_c('h2',{staticClass:"primary--text"},[_vm._v(" Entreprises ")])]),_c('div',[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('EntrepriseDialog',{attrs:{"operation":"create"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Ajouter une entreprise ")],1)]}}])})],1),_c(VSimpleTable,{ref:"table",staticClass:"overflow-x-auto overflow-y-auto",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"primary"},[_c('th',{staticClass:"white--text text-left"},[_vm._v("Nom de l'entreprise")]),_c('th',{staticClass:"white--text text-left"},[_vm._v("Métiers")]),_c('th',{staticClass:"white--text text-left"},[_vm._v("Téléphone")]),_c('th',{staticClass:"white--text"})]),_vm._l((_vm.entreprises),function(entreprise){return _c('tr',{key:entreprise.id},[_c('td',[_c('h3',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/entreprises/' + entreprise.id + '/'}},[_vm._v(_vm._s(entreprise.name)+" ")])],1)]),_c('td',_vm._l((entreprise.metiers),function(metier){return _c(VChip,{key:metier.id,attrs:{"color":"primary"}},[_vm._v(_vm._s(metier.name))])}),1),_c('td',[_vm._v(_vm._s(entreprise.tel))]),_c('td')])})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }